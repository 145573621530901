@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

.l-header {
  &__inner {
    @include pc01($point-pc01) {
      padding: 50px 50px 0;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      min-width: 1450px;
    }
  }
  &__btn {
    @include sp($point-sp) {
      padding: 12px 4px 12px 10px;
      width: 100%;
    }
    @include pc01($point-pc01) {
      display: none;
    }
    a {
      @include sp($point-sp) {
        display: block;
        width: 53px;
        height: 15px;
      }
    }
    span {
      display: none;
    }
    &.is-close {
      @include sp($point-sp) {
        z-index: 10;
        position: fixed;
        background-color: rgba(255, 255, 255, .9);
      }
      a {
        @include sp($point-sp) {
          background: url(http://sorrykoubou.jp/shop-pro/img/common/menu_open.svg) no-repeat;
          background-size: 47px 15px;
        }
      }
      & + .l-header__nav {
        @include sp($point-sp) {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &.is-open {
      @include sp($point-sp) {
        position: fixed;
        z-index: 120;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color .5s;
      }
      a {
        @include sp($point-sp) {
          background: url(http://sorrykoubou.jp/shop-pro/img/common/menu_close.svg) no-repeat;
          background-size: 53px 15px;
          z-index: 100;
        }
      }
      & + .l-header__nav {
        @include sp($point-sp) {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__campaign {
    display: none !important;
    font-size: 13px;
    line-height: 18px;
    position: relative;background: url(http://sorrykoubou.jp/shop-pro/img/top/line_wave.svg) bottom left no-repeat;
    background-size: 100% 8px;
    padding-bottom: 5px;
    letter-spacing: 0.15em;
    color: #0000c8;
    @at-root .topPage & {
      display: inline-block !important;
    }
  }
  &__nav {
    @include sp($point-sp) {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: left;
      width: 100vw;
      // height: 100vh;
      background: #e1f7fa;
      transition: opacity, .5s;
      z-index: 110;
      overflow: scroll;
    }
    @include pc01($point-pc01) {
      width: 100%;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      letter-spacing: 1px;
    }
    &List {
      list-style: none;
      @include pc01($point-pc01) {
        display: flex;
      }
      &--sp {
        @include sp($point-sp) {
          padding: 22px 36px;
        }
        @include pc01($point-pc01) {
          display: none;
        }
      }
      &.bgWhite {
        background: #fff;
      }
      &.main {
        @include sp($point-sp) {
          padding: 10px 36px 28px;
        }
        .l-header__navItem {
          @include sp($point-sp) {
            margin: 0;
            border-bottom: 1px solid $main-color;
          }
          a {
            @include sp($point-sp) {
              padding: 17px 0;
              display: block;
            }
          }
          &:first-child {
            @include sp($point-sp) {
              border-top: 1px solid $main-color;
            }
          }
          &.markBeginning {
            a {
              @include sp($point-sp) {
                position: relative;
                left: -6px;
              }
            }
          }
        }
      }
      &.sub {
        .l-header__navItem {
          @include sp($point-sp) {
          }
          a {
            @include sp($point-sp) {
              padding: 0 0 0 10px;
              position: relative;
            }
            &::before {
              @include sp($point-sp) {
                content: "";
                position: absolute;
                width: 5px;
                height: 1px;
                background: $main-color;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      hr {
        @include sp($point-sp) {
          width: 100%;
          height: 1px;
          background: #0000c8;
          border: none;
          margin: 23px 0;
        }
      }
    }
    &Item {
      @include sp($point-sp) {
        font-size: 14px;
        margin: 0 0 18px;
        letter-spacing: 0;
      }
      @include pc01($point-pc01) {
        border-left: 1px solid #0000c8;
      }
      &:first-child {
        @include pc01($point-pc01) {
          border-left: none;
        }
        a {
          @include sp($point-sp) {

          }
          @include pc01($point-pc01) {
            padding: 0 20px 0 0;
          }
        }
      }
      &:last-child {
        @include sp($point-sp) {
          margin: 0 !important;
        }
      }
      &.small {
        @include sp($point-sp) {
          font-size: 12px;
          margin: 0 0 17px;
        }
      }
      a {
        color: #0000c8;
        @include sp($point-sp) {
        }
        @include pc01($point-pc01) {
          color: #0000c8;
          padding: 0 20px;
          line-height: 18px;
          display: inline-block;
          position: relative;
        }
        &:hover {
          @include pc01($point-pc01) {
            text-decoration: none;
          }
        }
      }
    }
    &Logo {
      @include sp($point-sp) {
        text-align: right;
        background: #fff;
        padding: 18px 16px;
      }
      a {
        @include sp($point-sp) {
          display: inline-block;
        }
      }
      img {
        @include sp($point-sp) {
          width: auto;
        }
      }
    }
    &.transition {
      @include sp($point-sp) {
        display: block;
      }
    }
  }
  &__navInnerWrap {
    @at-root .topPage & {
      display: flex !important;
      justify-content: space-between;
    }
    width: calc(100% - 330px);
    min-width: 1050px;
  }
  &__close {

  }
  &__menu {
    list-style: none;
    @include sp($point-sp) {

    }
    @include pc01($point-pc01) {
      display: flex;
      align-items: center;
    }
    &Item {
      position: relative;
      &::before {
        @include pc01($point-pc01) {
          content: "";
          width: 1px;
          height: 17px;
          position: absolute;
          background: #0000c8;
          left: 0;
        }
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
      a {
        color: #0000c8;
        @include sp($point-sp) {
        }
        @include pc01($point-pc01) {
          line-height: 18px;
          display: inline-block;
          position: relative;
        }
        &:hover {
          @include pc01($point-pc01) {
            text-decoration: none;
          }
        }
      }
      &.login {
        a {
          @include pc01($point-pc01) {
            position: relative;
            padding: 0 20px 0 34px;
          }
          &::after {
            @include pc01($point-pc01) {
              content: "";
              position: absolute;
              left: 0;
              top: -3px;
              width: 19px;
              height: 23px;
              background: url(http://sorrykoubou.jp/shop-pro/img/common/login.svg) no-repeat;
            }
          }
        }
      }
      &.shoppingCage {
        a {
          @include pc01($point-pc01) {
            position: relative;
            padding: 0 0 0 58px;
          }
          &::after {
            @include pc01($point-pc01) {
              content: "";
              position: absolute;
              left: 20px;
              top: -1px;
              width: 26px;
              height: 20px;
              background: url(http://sorrykoubou.jp/shop-pro/img/common/shopping_cage.svg) no-repeat;
            }
          }
        }
      }
    }
  }
}
