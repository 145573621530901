@charset "utf-8";

/*
@include sp($point-sp) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

body {
  color: $main-color;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  line-height: 1;
  @include sp($point-sp) {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  @include pc01($point-pc01) {
    font-size: 16px;
    letter-spacing: 1.5px;
  }
}

html>body {
  // min-width: 980px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

a {
  color: $main-color;
  text-decoration: none;
  outline: none;
  -webkit-touch-callout: none;
}

a:hover {
  @include pc01($point-pc01) {
    text-decoration: underline;
  }
}

a:focus,
*:focus {
  outline: none;
}

img {
  vertical-align: top;
  border: none;
  border-style: none;
}

body {
  margin: 0;
}

a {
  @include ov;
}

br.SPbr {
  display: none;
  @include sp($point-sp) {
    display: inline;
  }
}

br.PCbr {
  display: inline;
  @include sp($point-sp) {
    display: none;
  }
}

.SPview {
  display: none !important;
  @include sp($point-sp) {
    display: block !important;
  }
}

.PCview {
  display: block !important;
  @include sp($point-sp) {
    display: none !important;
  }
}
.PCviewInline {
  display: none;
  @include pc01($point-pc01) {
    display: inline;
  }
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
//  border: none;
//  border-radius: 0;
//  outline: none;
//  -webkit-appearance: none;
//  -moz-appearance: none;
//  appearance: none;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

//フッター下固定
html,
body {
  height: 100%;
}

.l-container {
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 100%;
}

#contents {
  padding-bottom: 40px;
  /*フッターの高さと同じ*/
}

.l-contents {
  &.menu-open {
    @include sp($point-sp) {
      position: fixed;
      // width: 100%;
      // height: 100%;
    }
  }
}
