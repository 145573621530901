@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

/* width */

.u-PCwAuto {
  @include pc01($point-pc01) {
    width: auto !important;
  }
}

.u-PCw308 {
  @include pc01($point-pc01) {
    width: 308px !important;
  }
}

.u-SPw100per {
  @include sp($point-sp) {
    width: 100% !important;
  }
}

/* display */

@include sp($point-sp) {
  .u-SPdpInlineBlock {
    display: inline-block !important;
  }
  .u-SPdpInline {
    display: inline !important;
  }
}

@include pc01($point-pc01) {
  .u-PCdpInlineBlock {
    display: inline-block !important;
  }
}

/* text-align */

.u-textCenter {
  text-align: center !important;
}
.u-textLeft {
  text-align: left !important;
}
.u-textRight {
  text-align: right !important;
}

@include sp($point-sp) {
  .u-SPtextCenter {
    text-align: center !important;
  }
  .u-SPtextLeft {
    text-align: left !important;
  }
  .u-SPtextRight {
    text-align: right !important;
  }
}

@include pc01($point-pc01) {
  .u-PCtextCenter {
    text-align: center !important;
  }
  .u-PCtextLeft {
    text-align: left !important;
  }
  .u-PCtextRight {
    text-align: right !important;
  }
}

.u-floatCenter {
  float: center;
}

.u-floatLeft {
  float: left;
}

.u-floatRight {
  float: right;
}

/* fontSize */

@include sp($point-sp) {
  .u-SPfontSize8 {
    font-size: 8px !important;
  }
  .u-SPfontSize10 {
    font-size: 10px !important;
  }
  .u-SPfontSize11 {
    font-size: 11px !important;
  }
  .u-SPfontSize12 {
    font-size: 12px !important;
  }
  .u-SPfontSize13 {
    font-size: 13px !important;
  }
  .u-SPfontSize14 {
    font-size: 14px !important;
  }
  .u-SPfontSize15 {
    font-size: 15px !important;
  }
  .u-SPfontSize16 {
    font-size: 16px !important;
  }
  .u-SPfontSize17 {
    font-size: 17px !important;
  }
  .u-SPfontSize18 {
    font-size: 18px !important;
  }
  .u-SPfontSize19 {
    font-size: 19px !important;
  }
  .u-SPfontSize20 {
    font-size: 20px !important;
  }
}

@include pc01($point-pc01) {
  .u-PCfontSize10 {
    font-size: 10px !important;
  }
  .u-PCfontSize11 {
    font-size: 11px !important;
  }
  .u-PCfontSize12 {
    font-size: 12px !important;
  }
  .u-PCfontSize13 {
    font-size: 13px !important;
  }
  .u-PCfontSize14 {
    font-size: 14px !important;
  }
  .u-PCfontSize15 {
    font-size: 15px !important;
  }
  .u-PCfontSize16 {
    font-size: 16px !important;
  }
  .u-PCfontSize17 {
    font-size: 17px !important;
  }
  .u-PCfontSize18 {
    font-size: 18px !important;
  }
  .u-PCfontSize19 {
    font-size: 19px !important;
  }
  .u-PCfontSize20 {
    font-size: 20px !important;
  }
  .u-PCfontSize30 {
    font-size: 30px !important;
  }
}

/* margin */

@include sp($point-sp) {
  .u-SPnoMrg {
    margin: 0 !important;
  }
  .u-SPMrgB0 {
    margin-bottom: 0 !important;
  }
  .u-SPMrgB3 {
    margin-bottom: 3px !important;
  }
  .u-SPMrgB4 {
    margin-bottom: 4px !important;
  }
  .u-SPMrg5 {
    margin: 5px !important;
  }
  .u-SPMrgT5 {
    margin-top: 5px !important;
  }
  .u-SPMrgR5 {
    margin-right: 5px !important;
  }
  .u-SPMrgB5 {
    margin-bottom: 5px !important;
  }
  .u-SPMrgL5 {
    margin-left: 5px !important;
  }
  .u-SPMrgB6 {
    margin-bottom: 6px !important;
  }
  .u-SPMrgB8 {
    margin-bottom: 8px !important;
  }
  .u-SPMrgB9 {
    margin-bottom: 9px !important;
  }
  .u-SPMrg10 {
    margin: 10px !important;
  }
  .u-SPMrgT10 {
    margin-top: 10px !important;
  }
  .u-SPMrgR10 {
    margin-right: 10px !important;
  }
  .u-SPMrgB10 {
    margin-bottom: 10px !important;
  }
  .u-SPMrgL10 {
    margin-left: 10px !important;
  }
  .u-SPMrgB14 {
    margin-bottom: 14px !important;
  }
  .u-SPMrg15 {
    margin: 15px !important;
  }
  .u-SPMrgT15 {
    margin-top: 15px !important;
  }
  .u-SPMrgR15 {
    margin-right: 15px !important;
  }
  .u-SPMrgB15 {
    margin-bottom: 15px !important;
  }
  .u-SPMrgL15 {
    margin-left: 15px !important;
  }
  .u-SPMrgB17 {
    margin-bottom: 17px !important;
  }
  .u-SPMrgB18 {
    margin-bottom: 18px !important;
  }
  .u-SPMrg20 {
    margin: 20px !important;
  }
  .u-SPMrgT20 {
    margin-top: 20px !important;
  }
  .u-SPMrgR20 {
    margin-right: 20px !important;
  }
  .u-SPMrgB20 {
    margin-bottom: 20px !important;
  }
  .u-SPMrgL20 {
    margin-left: 20px !important;
  }
  .u-SPMrgB21 {
    margin-bottom: 21px !important;
  }
  .u-SPMrgB22 {
    margin-bottom: 22px !important;
  }
  .u-SPMrgB24 {
    margin-bottom: 24px !important;
  }
  .u-SPMrgB25 {
    margin-bottom: 25px !important;
  }
  .u-SPMrgB26 {
    margin-bottom: 26px !important;
  }
  .u-SPMrgB27 {
    margin-bottom: 27px !important;
  }
  .u-SPMrg30 {
    margin: 30px !important;
  }
  .u-SPMrgT30 {
    margin-top: 30px !important;
  }
  .u-SPMrgR30 {
    margin-right: 30px !important;
  }
  .u-SPMrgB30 {
    margin-bottom: 30px !important;
  }
  .u-SPMrgL30 {
    margin-left: 30px !important;
  }
  .u-SPMrgB32 {
    margin-bottom: 32px !important;
  }
  .u-SPMrgB35 {
    margin-bottom: 35px !important;
  }
  .u-SPMrgB36 {
    margin-bottom: 36px !important;
  }
  .u-SPMrg40 {
    margin: 40px !important;
  }
  .u-SPMrgT40 {
    margin-top: 40px !important;
  }
  .u-SPMrgR40 {
    margin-right: 40px !important;
  }
  .u-SPMrgB40 {
    margin-bottom: 40px !important;
  }
  .u-SPMrgL40 {
    margin-left: 40px !important;
  }
  .u-SPMrgB42 {
    margin-bottom: 42px !important;
  }
  .u-SPMrgB46 {
    margin-bottom: 46px !important;
  }
  .u-SPMrg50 {
    margin: 50px !important;
  }
  .u-SPMrgT50 {
    margin-top: 50px !important;
  }
  .u-SPMrgR50 {
    margin-right: 50px !important;
  }
  .u-SPMrgB50 {
    margin-bottom: 50px !important;
  }
  .u-SPMrgB60 {
    margin-bottom: 60px !important;
  }
  .u-SPMrgL50 {
    margin-left: 50px !important;
  }
  .u-SPMrgB100 {
    margin-bottom: 100px !important;
  }
  .u-SPMrgB144 {
    margin-bottom: 144px !important;
  }
}

@include pc01($point-pc01) {
  .u-PCnoMrg {
    margin: 0 !important;
  }
  .u-PCMrgB0 {
    margin-bottom: 0 !important;
  }
  .u-PCMrg5 {
    margin: 5px !important;
  }
  .u-PCMrgT5 {
    margin-top: 5px !important;
  }
  .u-PCMrgR5 {
    margin-right: 5px !important;
  }
  .u-PCMrgB5 {
    margin-bottom: 5px !important;
  }
  .u-PCMrgL5 {
    margin-left: 5px !important;
  }
  .u-PCMrg10 {
    margin: 10px !important;
  }
  .u-PCMrgT10 {
    margin-top: 10px !important;
  }
  .u-PCMrgR10 {
    margin-right: 10px !important;
  }
  .u-PCMrgB10 {
    margin-bottom: 10px !important;
  }
  .u-PCMrgL10 {
    margin-left: 10px !important;
  }
  .u-PCMrg15 {
    margin: 15px !important;
  }
  .u-PCMrgT15 {
    margin-top: 15px !important;
  }
  .u-PCMrgR15 {
    margin-right: 15px !important;
  }
  .u-PCMrgB15 {
    margin-bottom: 15px !important;
  }
  .u-PCMrgL15 {
    margin-left: 15px !important;
  }
  .u-PCMrgB18 {
    margin-bottom: 18px !important;
  }
  .u-PCMrg20 {
    margin: 20px !important;
  }
  .u-PCMrgT20 {
    margin-top: 20px !important;
  }
  .u-PCMrgR20 {
    margin-right: 20px !important;
  }
  .u-PCMrgB20 {
    margin-bottom: 20px !important;
  }
  .u-PCMrgL20 {
    margin-left: 20px !important;
  }
  .u-PCMrgB23 {
    margin-bottom: 23px !important;
  }
  .u-PCMrgB25 {
    margin-bottom: 25px !important;
  }
  .u-PCMrgB26 {
    margin-bottom: 26px !important;
  }
  .u-PCMrg30 {
    margin: 30px !important;
  }
  .u-PCMrgT30 {
    margin-top: 30px !important;
  }
  .u-PCMrgR30 {
    margin-right: 30px !important;
  }
  .u-PCMrgB30 {
    margin-bottom: 30px !important;
  }
  .u-PCMrgL30 {
    margin-left: 30px !important;
  }
  .u-PCMrgB32 {
    margin-bottom: 32px !important;
  }
  .u-PCMrgB36 {
    margin-bottom: 36px !important;
  }
  .u-PCMrgB38 {
    margin-bottom: 38px !important;
  }
  .u-PCMrg40 {
    margin: 40px !important;
  }
  .u-PCMrgT40 {
    margin-top: 40px !important;
  }
  .u-PCMrgR40 {
    margin-right: 40px !important;
  }
  .u-PCMrgB40 {
    margin-bottom: 40px !important;
  }
  .u-PCMrgL40 {
    margin-left: 40px !important;
  }
  .u-PCMrgB44 {
    margin-bottom: 44px !important;
  }
  .u-PCMrgB46 {
    margin-bottom: 46px !important;
  }
  .u-PCMrgB48 {
    margin-bottom: 48px !important;
  }
  .u-PCMrg50 {
    margin: 50px !important;
  }
  .u-PCMrgT50 {
    margin-top: 50px !important;
  }
  .u-PCMrgR50 {
    margin-right: 50px !important;
  }
  .u-PCMrgB50 {
    margin-bottom: 50px !important;
  }
  .u-PCMrgL50 {
    margin-left: 50px !important;
  }
  .u-PCMrgB52 {
    margin-bottom: 52px !important;
  }
  .u-PCMrgB55 {
    margin-bottom: 55px !important;
  }
  .u-PCMrgB60 {
    margin-bottom: 60px !important;
  }
  .u-PCMrgB64 {
    margin-bottom: 64px !important;
  }
  .u-PCMrgB70 {
    margin-bottom: 70px !important;
  }
  .u-PCMrgB72 {
    margin-bottom: 72px !important;
  }
  .u-PCMrgB76 {
    margin-bottom: 76px !important;
  }
  .u-PCMrgB80 {
    margin-bottom: 80px !important;
  }
  .u-PCMrgB84 {
    margin-bottom: 84px !important;
  }
  .u-PCMrgB90 {
    margin-bottom: 90px !important;
  }
  .u-PCMrgB100 {
    margin-bottom: 100px !important;
  }
  .u-PCMrgB110 {
    margin-bottom: 110px !important;
  }
  .u-PCMrgB120 {
    margin-bottom: 120px !important;
  }
  .u-PCMrgB115 {
    margin-bottom: 115px !important;
  }
  .u-PCMrgB144 {
    margin-bottom: 144px !important;
  }
}

/* padding */

@include sp($point-sp) {
  .u-SPnoPdd {
    padding: 0 !important;
  }
  .u-SPpddT0 {
    padding-top: 0 !important;
  }
  .u-SPpdd5 {
    padding: 5px !important;
  }
  .u-SPpddT5 {
    padding-top: 5px !important;
  }
  .u-SPpddR5 {
    padding-right: 5px !important;
  }
  .u-SPpddB5 {
    padding-bottom: 5px !important;
  }
  .u-SPpddL5 {
    padding-left: 5px !important;
  }
  .u-SPpddT6 {
    padding-top: 6px !important;
  }
  .u-SPpdd10 {
    padding: 10px !important;
  }
  .u-SPpddT10 {
    padding-top: 10px !important;
  }
  .u-SPpddR10 {
    padding-right: 10px !important;
  }
  .u-SPpddB10 {
    padding-bottom: 10px !important;
  }
  .u-SPpddL10 {
    padding-left: 10px !important;
  }
  .u-SPpdd15 {
    padding: 15px !important;
  }
  .u-SPpddT15 {
    padding-top: 15px !important;
  }
  .u-SPpddR15 {
    padding-right: 15px !important;
  }
  .u-SPpddB15 {
    padding-bottom: 15px !important;
  }
  .u-SPpddL15 {
    padding-left: 15px !important;
  }
  .u-SPpdd20 {
    padding: 20px !important;
  }
  .u-SPpddT20 {
    padding-top: 20px !important;
  }
  .u-SPpddR20 {
    padding-right: 20px !important;
  }
  .u-SPpddB20 {
    padding-bottom: 20px !important;
  }
  .u-SPpddL20 {
    padding-left: 20px !important;
  }
  .u-SPpddT25 {
    padding-top: 25px !important;
  }
  .u-SPpdd30 {
    padding: 30px !important;
  }
  .u-SPpddT30 {
    padding-top: 30px !important;
  }
  .u-SPpddR30 {
    padding-right: 30px !important;
  }
  .u-SPpddB30 {
    padding-bottom: 30px !important;
  }
  .u-SPpddL30 {
    padding-left: 30px !important;
  }
  .u-SPpdd40 {
    padding: 40px !important;
  }
  .u-SPpddT40 {
    padding-top: 40px !important;
  }
  .u-SPpddR40 {
    padding-right: 40px !important;
  }
  .u-SPpddB40 {
    padding-bottom: 40px !important;
  }
  .u-SPpddL40 {
    padding-left: 40px !important;
  }
  .u-SPpdd50 {
    padding: 50px !important;
  }
  .u-SPpddT50 {
    padding-top: 50px !important;
  }
  .u-SPpddR50 {
    padding-right: 50px !important;
  }
  .u-SPpddB50 {
    padding-bottom: 50px !important;
  }
  .u-SPpddL50 {
    padding-left: 50px !important;
  }
  .u-SPpddT70 {
    padding-top: 70px !important;
  }
}

@include pc01($point-pc01) {
  .u-PCnoPdd {
    padding: 0 !important;
  }
  .u-PCpddT0 {
    padding-top: 0 !important;
  }
  .u-PCpdd5 {
    padding: 5px !important;
  }
  .u-PCpddT5 {
    padding-top: 5px !important;
  }
  .u-PCpddR5 {
    padding-right: 5px !important;
  }
  .u-PCpddB5 {
    padding-bottom: 5px !important;
  }
  .u-PCpddL5 {
    padding-left: 5px !important;
  }
  .u-PCpdd10 {
    padding: 10px !important;
  }
  .u-PCpddT10 {
    padding-top: 10px !important;
  }
  .u-PCpddR10 {
    padding-right: 10px !important;
  }
  .u-PCpddB10 {
    padding-bottom: 10px !important;
  }
  .u-PCpddL10 {
    padding-left: 10px !important;
  }
  .u-PCpdd15 {
    padding: 15px !important;
  }
  .u-PCpddT15 {
    padding-top: 15px !important;
  }
  .u-PCpddR15 {
    padding-right: 15px !important;
  }
  .u-PCpddB15 {
    padding-bottom: 15px !important;
  }
  .u-PCpddL15 {
    padding-left: 15px !important;
  }
  .u-PCpdd20 {
    padding: 20px !important;
  }
  .u-PCpddT20 {
    padding-top: 20px !important;
  }
  .u-PCpddR20 {
    padding-right: 20px !important;
  }
  .u-PCpddB20 {
    padding-bottom: 20px !important;
  }
  .u-PCpddL20 {
    padding-left: 20px !important;
  }
  .u-PCpdd30 {
    padding: 30px !important;
  }
  .u-PCpddT30 {
    padding-top: 30px !important;
  }
  .u-PCpddR30 {
    padding-right: 30px !important;
  }
  .u-PCpddB30 {
    padding-bottom: 30px !important;
  }
  .u-PCpddL30 {
    padding-left: 30px !important;
  }
  .u-PCpdd40 {
    padding: 40px !important;
  }
  .u-PCpddT40 {
    padding-top: 40px !important;
  }
  .u-PCpddR40 {
    padding-right: 40px !important;
  }
  .u-PCpddB40 {
    padding-bottom: 40px !important;
  }
  .u-PCpddL40 {
    padding-left: 40px !important;
  }
  .u-PCpddT44 {
    padding-top: 44px !important;
  }
  .u-PCpddT46 {
    padding-top: 46px !important;
  }
  .u-PCpdd50 {
    padding: 50px !important;
  }
  .u-PCpddT50 {
    padding-top: 50px !important;
  }
  .u-PCpddR50 {
    padding-right: 50px !important;
  }
  .u-PCpddB50 {
    padding-bottom: 50px !important;
  }
  .u-PCpddL50 {
    padding-left: 50px !important;
  }
  .u-PCpddT148{
    padding-top: 148px !important;
  }
}

/* font-weight */

.u-fwBold {
  font-weight: bold;
}
.u-fwNormal {
  font-weight: normal;
}

@include sp($point-sp) {
  .u-SPfwBold {
    font-weight: bold;
  }
  .u-SPfwNormal {
    font-weight: normal;
  }
}

@include pc01($point-pc01) {
  .u-PCfwBold {
    font-weight: bold;
  }
  .u-PCfwNormal {
    font-weight: normal;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
}

/* line-height */

@include sp($point-sp) {
  .u-SPLh15 {
    line-height: 1.5 !important;
  }
  .u-SPLh18 {
    line-height: 1.8 !important;
  }
}

@include pc01($point-pc01) {
  .u-PCLh18 {
    line-height: 1.8 !important;
  }
  .u-PCLh22 {
    line-height: 2.2 !important;
  }
}

/* letter-spacing */

.u-Ls0 {
  letter-spacing: 0 !important;
}

@include sp($point-sp) {
}

@include pc01($point-pc01) {
  .u-PCLs0 {
    letter-spacing: 0 !important;
  }
  .u-PCLs10 {
    letter-spacing: 1px !important;
  }
  .u-PCLs12 {
    letter-spacing: 1.2px !important;
  }
}

/* text color */
.text-red {
  color: #f00;
}

// anchor位置調整
.SPanchor {
  @include sp($point-sp) {
    margin-top:-39px;
    padding-top:39px;
  }
}

.ls0 {
  letter-spacing: 0;
}
