.c-productBeginner {
  border-bottom: 1px solid #0000aa;
  @include sp($point-sp) {
    margin-bottom: 18px;
    padding-bottom: 9px;
    letter-spacing: 0.08em;
    line-height: (27/17);
    font-size: 10px;
    padding-top: 20px;
  }
  @include pc01($point-pc01) {
    margin-bottom: 43px;
    padding-bottom: 45px;
    letter-spacing: 0.13em;
    line-height: (26/15);
    font-size: 15px;
  }
  .c-subtitle {
    @include sp($point-sp) {
      font-size: 10px;
      margin-bottom: 9px;
    }
  }
  .c-productBeginner__main {
    @include sp($point-sp) {
      margin-bottom: 10px;

    }
    @include pc01($point-pc01) {
      margin-bottom: 35px;
    }
    a {
      display: block;
      &:hover {
        @include pc01($point-pc01) {
          text-decoration: none;
        }
      }
    }

    .c-productBeginner__mainImg {
      @include sp($point-sp) {
        margin-bottom: 8px;

      }
      @include pc01($point-pc01) {
        margin-bottom: 16px;
        padding-top: 8px;
      }
      img {
        width: 100%;
      }

    }
    .c-productBeginner__mainTitle {
      text-align: center;
      @include sp($point-sp) {
        margin-bottom: 8px;

      }
      @include pc01($point-pc01) {
        margin-bottom: 20px;
      }
      .price {
        @include sp($point-sp) {

        }
        @include pc01($point-pc01) {
          font-size: 14px;
        }
      }
    }
    .c-productBeginner__mainCatch {
      text-align: center;

    }
  }
  .c-productBeginner__sub {
    .c-productBeginner__subList {
      display: flex;
      justify-content: space-between;

      .c-productBeginner__subItem {
        @include sp($point-sp) {
          width: calc((100% - 20px) / 3);
          flex: 0 0 calc((100% - 20px) / 3);
        }
        @include pc01($point-pc01) {
          width: 248px;
          flex: 0 0 248px;

        }
        a {
          display: block;
          &:hover {
            @include pc01($point-pc01) {
              text-decoration: none;
            }
          }
        }
         .c-productBeginner__subImg {
           @include sp($point-sp) {
             margin-bottom: 8px;

           }
           @include pc01($point-pc01) {
             margin-bottom: 17px;
           }
        }
         .c-productBeginner__subTitle {
           text-align: center;

        }


      }
    }
  }
}
