@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

.c-categorylist {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $main-color;
  width: 100%;
  text-align: center;
  &:nth-of-type(2) {
    .c-categorylistItem:nth-child(3){
      &::after {
        display: none;
      }
    }
    a {
      @include pc01($point-pc01) {
        padding: 15px 66px;
      }
    }
  }
  &Wrap {
    border-top: 1px solid $main-color;
    @include sp($point-sp) {
      margin-bottom: 20px;
    }
    @include pc01($point-pc01) {
      margin-bottom: 40px;
    }
  }
  &Item {
    position: relative;
    flex: 1 0 auto;
    &:nth-of-type(4) {
      &::after {
        display: none;
      }
    }
    &::after{
      border-right: 1px solid $main-color;
      right: 0;
      width: 1px;
      content: '';
      position: absolute;
      @include sp($point-sp) {
        height: 18px;
        top: 8px;
      }
      @include pc01($point-pc01) {
        height: 26px;
        top: 11px;
      }
    }
    a {
      display: block;
      @include sp($point-sp){
        font-size: 10px;
        padding: 12px 0;
        text-align: center;
      }
      @include pc01($point-pc01) {
        padding: 15px 63px 15px 64px;
        font-size: 16px;
      }
    }
  }
}
